<template>
    <b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
        <template slot="title">
            <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Consultations
        </template>
        <b-form-group
            label="Consultations Reasons"
            label-size="lg"
            label-class="font-weight-bold pt-0">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
                    <b-overlay 
                        :show="$store.getters['loading/isLoading']('consultation')"
                        rounded="sm"
                        variant="light"
                        :opacity="0.80"
                        blur="2px">
                        <b-row>
                            <b-col>
                                <b-card no-body>
                                    <template v-slot:header>
                                        <span class="align-middle">Available Reasons</span>
                                        <div class="card-header-actions">
                                            <NewConsultReason @created="onCreated" />
                                        </div>
                                    </template>
                                    <b-list-group flush>
                                        <b-list-group-item class="p-2 flex-column align-items-start" v-for="(element, index) in available" :key="element.id">
                                            <div class="d-flex w-100 justify-content-between">
                                                <UpdateConsultReason :item="element" />
                                                <b-link @click="add(element)">
                                                    <i class="fa-solid fa-plus align-middle"></i>
                                                </b-link>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item class="text-center" v-if="noAvailableReasons"><i class="fa-solid fa-ban fa-xl"></i></b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-col>
                            <b-col>
                                <b-card no-body>
                                    <template slot="header">
                                        <span class="align-middle">Activated Reasons</span>
                                        <div class="card-header-actions">
                                            <b-button size="sm" class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                                            <b-button size="sm" v-show="isDirty | hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
                                        </div>
                                    </template>
                                    <b-list-group flush>
                                        <b-list-group-item class="p-2 flex-column align-items-start" v-for="(element, index) in selected" :key="element.id">
                                            <div class="d-flex w-100 justify-content-between">
                                                <UpdateConsultReason :item="element" />
                                                <b-link @click="removeAt(index)">
                                                    <i class="fa-solid fa-minus align-middle"></i>
                                                </b-link>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item class="text-center" v-if="noSelectedReasons">Add reasons</b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-overlay>
                   <b-form-group>

                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-form-group>
    </b-tab>
</template>
<script>
import BaseExtension from "@/views/BaseExtension"
import { mapGetters, mapState, mapActions } from "vuex"

import NewConsultReason from './NewConsultReason'
import UpdateConsultReason from './UpdateConsultReason'

import _ from 'lodash'

export default BaseExtension.extend({
    name: 'user-consultations',
    components: {
        NewConsultReason,
        UpdateConsultReason
    },
    data() {
        return {
            form: new Form({
                consult_reason_types: [],
                consistency_token: null
            }),

            available: [],
            selected: [],

            isDirty: false,
            hasErrors: false,

            update: false
        }
    },
    computed: {
        ...mapGetters('planning', ['getSettings', 'getReasonTypes']),
        settings() {
            return this.getSettings()
        },
        reasonTypes() {
            return this.getReasonTypes()
        },
        availableReasons() {
            return this.available
        },
        selectedReasons() {
            return this.selected
        },
        noAvailableReasons() {
            return this.availableReasons.length < 1
        },
        noSelectedReasons() {
            return this.selectedReasons.length < 1
        }
    },
    watch: {
        settings: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        },

        reasonTypes: {
            immediate: true,
            handler(val) {
                this.loadReasons()
            }
        },
    },
    methods: {
        ...mapActions('planning', ['getUserCalendarSettings', 'updateCalendarSettings', 'getConsultReasonTypes', 'createConsultReasonType', 'updateConsultReasonTypeById', 'deleteConsultReasonTypeById']),
        async onCreated() {
            await this.getConsultReasonTypes()
        },
        onFormSubmit() {
            const reasons = this.selected.map(reason => reason.id)
            this.form.populate({consult_reason_types: reasons})
            this.updateCalendarSettings(this.form.data()).then((settings) => {
                this.form.withData(settings)
                this.reset()
                this.notify('Consultation reasons updated', "Consultation reasons was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        removeAt(idx) {
            this.selected.splice(idx, 1)
            this.isDirty = true
            this.available = this.reasonTypes.filter(event => !this.selected.includes(event))
        },
        add(element) {
            this.selected.push(element)
            this.isDirty = true
            this.available = this.reasonTypes.filter(event => !this.selected.includes(event))
        },
        reset() {
            this.$refs.observer.reset()
            this.loadReasons()
            this.$nextTick(() => {
                this.hasErrors = false
                this.isDirty = false
                this.saved(this.$vnode.key)
            })
        },
        loadReasons() {
            this.available = this.reasonTypes.filter(event => !this.form.consult_reason_types.includes(event.id))
            this.selected = this.reasonTypes.filter(event => this.form.consult_reason_types.includes(event.id))
        }
    },
    async mounted() {
        await this.getUserCalendarSettings(this.user?.username)
        await this.getConsultReasonTypes()

    }
})
</script>