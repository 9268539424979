// Planning
const PlanningContainer = () => import("@/views/planning/containers/DefaultContainer")
// const PlanningIndex = () => import("@/views/planning/views/Index")
const PlanningIndex = () => import("@/views/planning/views/StartingPage")

const PractitionerCalendar = () => import("@/views/planning/views/PractitionerCalendar")

const routes = [
    {
        path: "/planning",
        component: PlanningContainer,
        meta: {
            label: "Planning"
        },
        children: [
            {
                path: "",
                component: PlanningIndex,
                meta: {
                    roleRequired: ["administration", "physician"],
                    label: "Welcome"
                }
            },
            {
                path: "physicians/:username",
                component: PractitionerCalendar,
                meta: {
                    roleRequired: ["administration", "physician"],
                    label: "Physicians"
                }
            }
        ]
    }
]

export default routes
