<template>
    <b-list-group-item class="flex-column align-items-start mx-1">
    <div class="d-flex w-100 justify-content-between">
        <div class="align-middle">
            <i class="mr-2 fa-solid fa-microscope"></i> {{referral.test.parent_name}}: {{referral.test.name}}
        </div>
        <div class="d-flex">
            <validation-observer ref="observer" v-slot="{ passes }">
                <b-form novalidate>
                    <validation-provider vid="result_value" v-slot="{ errors }">
                    <b-input-group>
                        <b-form-input size="sm" style="width:100px;" :state="errors[0] ? false : null" id="result_value" name="result_value" v-model="form.result_value" placeholder="Set..." @blur="passes(onFormSubmit)" @keyup="passes(onFormSubmit)"/>
                        <b-input-group-append is-text v-if="form.result_value">
                            <b-spinner v-if="loading" small label="Small Spinner"></b-spinner>
                            <i v-else class="fa-solid fa-check text-success"></i>
                        </b-input-group-append>
                    </b-input-group>
                    </validation-provider>
                </b-form>
            </validation-observer>
        </div>
    </div>
    </b-list-group-item>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"

export default {
    name: "lab-referral-test-item",
    props: ['referral'],
    data() {
        return {
            form: new Form({
                result_value: null,
                consistency_token: null
            }),

            loading: false
        }
    },
    computed: {
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        referral: {
            immediate: true,
            handler(val) {
                this.form.withData(val)
            }
        }
    },
    methods: {
        ...mapActions('lab', ['updateLabReferralTest']),
        onFormSubmit() {
            this.loading = true
            this.updateLabReferralTest({id: this.referral.id, form: this.form.data() }).then(() => {
                this.loading = false
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.loading = false
                }
            })
        }
    }
}
</script>