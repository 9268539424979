<template>
    <b-card class="border-0">
        <template v-for="plugin in plugins">
            <h6><i class="fa-solid" :class="plugin.icon"></i> {{ plugin.title }}</h6>
            <component :is="'pmh-' + plugin.key" :key="plugin.key" :limit="3"></component>
        </template>
    </b-card>
</template>
<script>
import PMHExtension from './PMHExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default PMHExtension.extend({
    name: "decursus-pmh",
})
</script>