<template>
    <div>
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div v-if="processing" class="d-flex align-items-center">
                    <strong>Removing...</strong>
                    <b-spinner class="ml-auto"></b-spinner>
                </div>
                <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE LAB REFERRAL DOCUMENT {{ labReferral.filename }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>

        <b-form-group>
            <template v-if="labReferral.filename">
            <b-input-group prepend="Result Document">
                <b-input-group-prepend>
                    <b-button variant="primary" @click="openDocument"><i class="fa-solid fa-eye"></i></b-button>
                </b-input-group-prepend>
                <b-form-input readonly v-model="labReferral.filename" />
                <b-input-group-append is-text>
                    uploaded {{toLocaleDate(labReferral.document_upload_date)}}
                </b-input-group-append>
                <b-input-group-append>
                    <b-button variant="danger" @click="onRemoveDocument"><i class="fa-solid fa-xmark"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </template>
            <template v-else>
                <b-input-group prepend="Upload Result Document">
                    <b-form-file ref="fileUpload" @input="onFileChange" :accept="formats" />
                </b-input-group>
            </template>
        </b-form-group>
        <b-form-group v-if="$store.getters['loading/isLoading']('document')">
            <b-progress height="20px" show-progress animated>
                <b-progress-bar :value="100" variant="success">
                    <span>Uploading...</strong></span>
                </b-progress-bar>
            </b-progress>
        </b-form-group>
    </div>
</template>

<script>
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import moment from "moment"

export default {
    props: {
        labReferral: {
            type: Object
        }
    },
    data() {
        return {
            formats: ".jpeg, .jpg, .png, .pdf",
            confirm: false,
            processing: false
        }
    },
    methods: {
        ...mapActions('lab', ['uploadLabDocument', 'deleteLabDocument']),
        async onFileChange(file) {
            const form = Form.create()
                .withData({
                    document: file,
                    consistency_token: this.labReferral.consistency_token
                })

                await this.uploadLabDocument({id: this.labReferral.id, document: form.formData()})
        },
        openDocument() {
            if(this.labReferral.document_extension === ".pdf") {
                window.open(this.labReferral.document_url, '_blank')
            }else{
                this.$viewerApi({
                  images: [this.labReferral.document_url],
                })
            }
        },
        onRemoveDocument() {
            this.confirm = true
            this.processing = false
        },
        onConfirmed() {
            this.processing = true
            this.deleteLabDocument(this.labReferral.id).then(() => {
                this.confirm = false
                this.processing = false

            })
            .catch(errors => {
                this.confirm = false
                this.processing = false
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
        }
    }
}
</script>