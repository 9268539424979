<template>
<b-tab lazy title-item-class="mb-1" :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Memo
    </template>
    <validation-observer ref="observer" v-slot="{ passes }">
        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('memo')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-form-group :disabled="isLocked">
                <validation-provider vid="description" v-slot="{ errors }">
                <b-form-group
                    :invalid-feedback="errors[0]"
                    :state="errors[0] ? false : null">
                    <b-form-textarea id="description" v-capitalize v-model="form.description" placeholder="Add memo..." rows="5"></b-form-textarea>
                </b-form-group>
                </validation-provider>
            </b-form-group>
        </b-overlay>
        <template v-if="checkUserPermission('memo', 'can_change')">
            <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
        </template>
    </b-form>
    </validation-observer>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TabExtension from './TabExtension'

export default TabExtension.extend({
    name: 'memo',
    data() {
        return {
            form: new Form({
            	description: "",
                consistency_token: null
            }),

            isLocked: true,
            isDirty: false,
            hasErrors: false
        }
    },
    computed: {
        ...mapGetters('patients', ['getPatientMemo']),
        memo() {
            return this.getPatientMemo()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        memo: {
            immediate: true,
            handler(val) {
                if (val)
                    this.form.withData(val)
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        }
    },
    methods: {
        ...mapActions('patients', ['createPatientMemo', 'updatePatientMemo']),
        create() {
            this.createPatientMemo(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient memo created", "Patient memo was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        update() {
            this.updatePatientMemo({id: this.patient.memo.id, data: this.form.data() }).then(() => {
                this.reset()
                this.notify("Patient memo updated", "Patient memo was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        onFormSubmit() {
            if (this.patient.memo) {
                this.update()
            }else{
                this.create()
            }
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        }
    }
})
</script>