import session from '@/shared/session'

const state = {
	patient: {
		lab_referrals: []
	}
}

// getters
const getters = {
	getPatientLabReferrals: (state) => () => {
		return state.patient.lab_referrals
	}
}

// actions
const actions = {
	async getLabReferralsByPatientId({ commit, state, rootState }) {
		const lab_referrals  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/lab/`)

		commit("set_lab_referrals", lab_referrals.data)

		return state.patient.lab_referrals
	},

	async getLabChartDataByPatientId({ commit, state, rootState }) {
		const chart  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/lab_chart/`)

		return chart.data
	},

	createLabReferral({ dispatch, commit, rootState }, data) {
		dispatch("loading/load", "lab", { root: true })
		return session
				.post(`/api/lab/`, {...data, decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getLabReferralsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "lab", { root: true })
	      })
	},

	deleteLabReferral({ dispatch }, id) {
		dispatch("loading/load", "lab", { root: true })
		return session
				.delete(`/api/lab/${id}/`)
				.then(() => {
					return dispatch("getLabReferralsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "lab", { root: true })
      })
	},

	uploadLabDocument({ commit, dispatch }, data) {
    dispatch("loading/load", "document", { root: true })
		return session
			.post(`/api/lab/${data.id}/document/`, data.document)
			.then((lab) => {
				return dispatch("getLabReferralsByPatientId")
			})
			.finally(() => {
				dispatch("loading/end", "document", { root: true })
			})
	},

	deleteLabDocument({ commit, dispatch }, id) {
    	dispatch("loading/load", "document", { root: true })
		return session
			.patch(`/api/lab/${id}/delete_document/`)
			.then((lab) => {
				return dispatch("getLabReferralsByPatientId")
			})
			.finally(() => {
				dispatch("loading/end", "document", { root: true })
			})
	},

	updateLabReferralTest({ commit, dispatch }, data) {
    dispatch("loading/load", "test", { root: true })
		return session
			.put(`/api/lab/tests/${data.id}/`, data.form)
			.then((test) => {
				// return dispatch("getLabReferralsByPatientId")
				return test.data
			})
			.finally(() => {
				dispatch("loading/end", "test", { root: true })
			})
	},
}

// mutations
const mutations = {
	set_lab_referrals(state, lab_referrals) {
		state.patient.lab_referrals = lab_referrals
	},
	set_lab_chart_data(state, chart_data) {
		state.patient.lab_chart_data = chart_data
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}