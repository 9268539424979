<template>
<b-tab no-body lazy>
    <template slot="title">
        Export Patient
    </template>
    <div v-if="checkUserPermission('export', 'can_execute')" class="d-flex mb-3">
        <b-overlay
            :show="exporting"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="ml-auto">
            <b-button :disabled="exporting" type="submit" variant="primary" @click="exportPatient">
                <i :class="{'fa-fade' : exporting}" class="fa-regular fa-file-export"></i> Export
            </b-button>
        </b-overlay>
    </div>
    <b-table
        ref="table"
        class="m-0"
        responsive
        stacked="sm"
        show-empty
        striped
        hover
        fixed
        small
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :filter-function="filter"
        :busy.sync="$store.getters['loading/isLoading']('export_patient')"
        :no-sort-reset="true"
        :sort-by.sync="sortedBy"
        :sort-desc.sync="sortedDesc"
        :no-border-collapse="false">

        <div slot="table-busy" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
        </div>

        <template v-slot:empty="scope">
            <!-- {{ scope.emptyText }} -->
            <div class="small text-muted text-center my-2">
                <span>N/A</span>
            </div>
        </template>

        <template v-slot:cell(actions)="row">
            <template v-if="row.item.ready">
                <b-button :disabled="row.item.downloaded" v-if="checkUserPermission('export', 'can_execute')" size="sm" variant="primary" @click="download(row.item)"><i class="fa-regular fa-file-arrow-down"></i></b-button>
            </template>
            <template v-else>
                <b-spinner small variant="success" label="Processing"></b-spinner>
            </template>
        </template>
        <template v-slot:custom-foot="scope">
            <b-tr class="border-bottom">
                <b-td class="align-middle" variant="light">
                    Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }} export
                </b-td>
                <b-td colspan="2" class="text-right align-middle" variant="light">
                    <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                </b-td>
            </b-tr>
        </template>
    </b-table>
</b-tab>
</template>
<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import TabExtension from "../TabExtension"
import _ from 'lodash'

export default TabExtension.extend({
    name: 'export-patient',
    data() {
        return {
            exporting: false,
            fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'user.username', label: 'By', class: 'small align-middle' },                
                { key: "actions", label: "Download", class: "small align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 10,
            sortedBy: 'id',
            sortedDesc: true,
            isBusy: false
        }
    },
    computed: {
        ...mapGetters('patients', ['getPatientExports']),
        items() {
            return this.getPatientExports()
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    methods: {
        ...mapActions('patients', ['getExportsForCurrentPatient', 'exportCurrentPatient', 'getRunningExportsForCurrentPatient']),
        ...mapActions('interchange', ['downloadExport']),
        filter() {
            this.$refs.table.refresh()
        },
        async exportPatient() {
            this.exporting = true
            await this.exportCurrentPatient('pdf')
        },
        async handleMessageReceived(message) {
            if (message == `${this.user?.username}_exported`) {
                await this.getExportsForCurrentPatient()
                this.exporting = false
            }
        },
        async download(item) {
            await this.downloadExport(item.id)
            await this.getExportsForCurrentPatient()
        }
    },
    async mounted() {
        this.exporting = await this.getRunningExportsForCurrentPatient()
        await this.getExportsForCurrentPatient()
        
        this.$websocket.$on('messageReceived', this.handleMessageReceived)
        this.$websocket.connect(`${window.location.host}/ws/connect/exports/?username=${this.user?.username}`)
    },
    beforeDestroy() {
        this.$websocket.$off('messageReceived', this.handleMessageReceived)
        this.$websocket.disconnect()
    }
})
</script>