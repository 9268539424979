<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Attests
    </template>
    <div class="d-flex mb-3" v-if="checkUserPermission('attest', 'can_add')">
        <b-button class="ml-auto" @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
    </div>

    <b-collapse id="add" v-model="addNew" variant="primary">
        <b-card>
            <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
                 <validation-provider vid="non_field_errors" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <validation-provider vid="company_name" v-slot="{ errors }">
                            <b-form-group
                                label="Company name"
                                label-for="company_name"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-input-group>
                                    <b-form-input id="company_name" name="company_name" placeholder="Add Company name..." v-model="form.company_name" />
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider name="Days" vid="days" v-slot="{ errors }">
                            <b-form-group
                                label="Days"
                                label-for="days"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                        <b-form-checkbox v-b-tooltip.hover title="Use days" v-model="hasAlternateDate" @change="onAlternateDateChange">
                                    </b-form-checkbox>
                                    </b-input-group-prepend>
                                    <b-form-input :disabled="!hasAlternateDate" type="number" id="days" name="days" min="0" step="0.5" placeholder="0.0" v-model="form.days" readonly />
                                    <b-input-group-append>
                                        <b-button :disabled="!hasAlternateDate" variant="danger" @click="decreaseDayStep">
                                            <i class="fa-regular fa-minus"></i>
                                        </b-button>
                                        <b-button :disabled="!hasAlternateDate" variant="success" @click="increaseDayStep">
                                            <i class="fa-regular fa-plus"></i>
                                        </b-button>
                                    </b-input-group-append>

                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                        <b-form-row>
                            <b-col>
                                <validation-provider vid="until_date" v-slot="{ errors }">
                                    <b-form-group
                                        label="From date"
                                        label-for="from_date"
                                        :invalid-feedback="errors[0]"
                                        :state="errors[0] ? false : null">
                                        <b-form-datepicker :disabled="hasAlternateDate" id="from_date" placeholder="From..." v-model="form.from_date" :locale="currentLang" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"></b-form-datepicker>

                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col>
                                <validation-provider vid="until_date" v-slot="{ errors }">
                                    <b-form-group
                                        label="Until date"
                                        label-for="until_date"
                                        :invalid-feedback="errors[0]"
                                        :state="errors[0] ? false : null">
                                        <b-form-datepicker :disabled="hasAlternateDate" id="until_date" placeholder="Until..." v-model="form.until_date" :locale="currentLang" :min="form.from_date" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"></b-form-datepicker>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </b-form-row>
                    </b-form-group>
                </validation-provider>

                <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
            </b-form>
            </validation-observer>
        </b-card>
    </b-collapse>
    <b-overlay :show="confirm" no-wrap @shown="onShown">
        <template #overlay>
            <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                <p class="text-center">
                    <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE ATTEST INFO ON {{ toLocaleDate(selected?.created) }} </strong>
                </p>
                <div class="text-center">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                    <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                </div>
            </div>
        </template>
    </b-overlay>
    <b-overlay 
        :show="$store.getters['loading/isLoading']('attests')"
        rounded="sm"
        variant="light"
        :opacity="0.80"
        blur="2px">
        <b-table
            ref="table"
            class="m-0"
            responsive
            stacked="sm"
            show-empty
            striped
            hover
            fixed
            small
            primary-key="id"
            :current-page="currentPage"
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :busy.sync="$store.getters['loading/isLoading']('attests')"
            :no-sort-reset="true"
            :sort-by.sync="sortedBy"
            :sort-desc.sync="sortedDesc"
            :no-border-collapse="false">
            <template v-slot:cell(height)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
            </template>

            <template v-if="checkUserPermission('attest', 'can_delete')" v-slot:cell(actions)="row">
                <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-light fa-xmark"></i></b-button>
            </template>

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template v-slot:empty="scope">
                <div class="small text-muted text-center my-2">
                    <span>N/A</span>
                </div>
            </template>

            <template v-slot:custom-foot="scope">
                <b-tr class="border-top">
                    <b-td colspan="2" class="pt-3 align-middle" variant="light" style="background: #fff;">
                        Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                    </b-td>
                    <b-td colspan="2" class="pt-3 text-right align-middle" variant="light" style="background: #fff;">
                        <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                    </b-td>
                </b-tr>
            </template>
        </b-table>
    </b-overlay>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

export default TabExtension.extend({
    name: "attests",
    data() {
        return {
            addNew: false,
            form: new Form({
                company_name: "",
                from_date: null,
                until_date: null,
                days: null,
                consistency_token: null
            }),

            fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'sum_days', label: 'Days', class: 'small align-middle text-center' },
                { key: 'company_name', label: 'Company', class: 'small align-middle text-center' },                
                { key: "actions", label: "", class: "small align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null,

            hasAlternateDate: true,
            dayStepValue: 0.5
        }
    },
    computed: {
        ...mapGetters('health', ['getPatientAttests']),
        items() {
            return this.getPatientAttests()
        },
        ...mapState('patients', {
            current_consult: state => state.current.current_consult,
        }),
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.$emit('dirty', this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.$emit('saved', this.$vnode.key)
                }
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('health', ['getAttestsByPatientId', 'createPatientAttestWithOrWithoutConsult', 'deletePatientAttest']),
        onRemoveItem(item) {
            this.confirm = true
            this.selected = item
        },
        onConfirmed(item) {
            this.deletePatientAttest(item.id).then(() => {
                this.notify("Patient attest removed", "Patient attest was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
        },
        onAlternateDateChange(value) {
            // clear date form data
            this.form.populate({from_date: null, until_date: null, days: null})
        },
        increaseDayStep() {
            const form = this.form.data()
            this.form.populate({days: form.days + this.dayStepValue})
        },
        decreaseDayStep() {
            const form = this.form.data()
            if (form.days > this.dayStepValue) {
                this.form.populate({days: form.days -= this.dayStepValue})
            }
        },
        onFormSubmit() {
            this.createPatientAttestWithOrWithoutConsult(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient attest created", "Patient attest was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                }
            })
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.addNew = false
                this.saved(this.$vnode.key)
            })
        }
    },
    async mounted() {
        this.getAttestsByPatientId()
    }
})
</script>