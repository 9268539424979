import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import patientsRoutes from './modules/patients'
import planningRoutes from './modules/planning'
import scheduleRoutes from './modules/schedule'
import messagingRoutes from './modules/messaging'

// Auth
const Login = () => import('@/views/auth/Login')
const Account = () => import('@/views/auth/Account')
const AccountContainer = () => import("@/views/auth/containers/AccountContainer")

const ForgotPassword = () => import('@/views/auth/ForgotPassword')
const ResetPassword = () => import('@/views/auth/ResetPassword')
const ChangePassword = () => import('@/views/auth/ChangePassword')

// Error Pages
const Page401 = () => import('@/views/errors/Page401')
const Page404 = () => import('@/views/errors/Page404')
const Page500 = () => import('@/views/errors/Page500')

const redirectLogout = (to, from, next) => {
    store.dispatch('auth/logout').then(() => next({ path: '/login', replace: true, query: { redirect: from.fullPath } }))
}

const redirectUser = (currentUser, to, from, next) => {
    const physician = currentUser.roles.some(role => role.name === 'physician')
    
    if (physician) {

        if (to.fullPath === '/planning') {
            return next({ path: `/planning/physicians/${currentUser.username}`})
        }
    }

    return next()
}

const redirectToRoot = (currentUser, to, from, next) => {
    const physician = currentUser.roles.some(role => role.name === 'physician')
    if (physician) {
        return next({ path: '/schedule' })
    }

    const administration = currentUser.roles.some(role => role.name === 'administration')

    if (administration) {
        return next({ path: '/planning' })
    }

    const nurse = currentUser.roles.some(role => role.name === 'nurse')

    if (nurse) {
        return next({ path: '/patients' })
    }
}

const baseRoutes = [
    {
        path: "/",
    },
    {
        path: '/login',
        component: Login,
        meta: {
            anonymous: true,
        }
    },
    {
        path: '/account',
        component: AccountContainer,
        meta: {
            anonymous: false,
        },
        children: [
            {
                path: "",
                component: Account,
                meta: {
                    anonymous: false,
                    label: "User Account"
                }
            }
        ]
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        meta: {
            anonymous: true,
        }
    },
    {
        path: '/password-reset',
        component: ResetPassword,
        meta: {
            anonymous: true,
        }
    },
    {
        path: '/password-change',
        component: ChangePassword,
        meta: {
            bypass: true,
        }
    },
    {
        path: '/logout',
        beforeEnter: redirectLogout,
    },
    {
        path: '/401',
        component: Page401
    },
    {
        path: '/404',
        component: Page404
    },
    {
        path: '/500',
        component: Page500
    },
    { path: "*", redirect: "/404" },
]

const routes = baseRoutes.concat(patientsRoutes, planningRoutes, scheduleRoutes, messagingRoutes)

Vue.use(Router)

const router = new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const { roleRequired } = to.meta;
    const { anonymous, bypass } = to.meta;
    const currentUser = store.state.auth.current

    if (!currentUser) {
        
        if (anonymous || bypass) {
            return next()
        }
        
        if (to.path !== "/login") {
            if (to.query.redirect !== undefined) {

                return next({ path: "/login", query: { redirect: to.fullPath } })
            }

            return next({ path: "/login" })
        }
    }

    if (currentUser.force_password_change && !bypass) { // redirect to change password view
        return next({ path: '/password-change' })
    }

    if (to.fullPath === '/') {        
        return redirectToRoot(currentUser, to, from, next)
    }

    if (roleRequired) {

        // check if route is restricted by role
        const authorized = currentUser.roles.some(role => roleRequired.includes(role.name))
        
        if (roleRequired.length && !authorized) {
          // role not authorised so show them there lost
            return next({ path: '/404' })
        }

        return redirectUser(currentUser, to, from, next)
    }

    next()
})

export default router
