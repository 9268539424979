<template>
	<div>
		<template v-for="(element, index) in values">
		<validation-provider :name="`${placeholder} #${index+1}`" :vid="`multiple-input-field-${index+1}`" :rules="rules" v-slot="{ errors }">
			<b-form-group
	            :invalid-feedback="errors[0]"
	            :state="errors[0] ? false : null">
				<b-input-group :prepend="prepend">
					<b-form-input :type="type" :size="size" :placeholder="`${placeholder} #${index+1}`" :name="`multiple-input-field-${index+1}`" v-model="element.text" @input="updateValue()"/>
					<template v-if="showControls">
						<template v-if="last === index">
							<b-input-group-append>
								<b-button :size="size" :disabled="isMax" type="button" variant="success" @click="addValue"><i class="fa-solid fa-plus"></i></b-button>
								<b-button :size="size" :disabled="isMin" type="button" variant="danger" @click="deleteValue"><i class="fa-solid fa-minus"></i></b-button>
							</b-input-group-append>
						</template>
					</template>
				</b-input-group>
			</b-form-group>
		</validation-provider>
		</template>
	</div>
</template>

<script>
export default {
	name: "multiple-input-field",
	props: {
		placeholder: {
			type: String,
			default: 'Set Placeholder'
		},
		value: {},
		type: {
			type: String,
			default: 'text'
		},
		showControls: {
			default: true
		},
		maxInputs: {
			default: 4
		},
		size: {
			default: 'md'
		},
		prepend: {
			default: ''
		},
		rules: {
			default: ''
		}
	},
	data() {
		return {
			values: [],
		}
	},
	computed: {
		isMin() {
			return this.values.length < 2
		},

		isMax() {
			return this.values.length == this.maxInputs
		},

		last() {
			return this.values.length - 1
		}
	},
	watch: {
		value: {
			handler(val) {
				if (!val.length) {
					this.values.length = 0
					this.values.push({})
				}
			},
		},
	},
	methods: {
		updateValue() {
			this.$emit('input', this.values)
		},
		deleteValue(index) {
		this.values.splice(-1)
			this.$emit('input', this.values)
		},
		addValue() {
			this.values.push({})
			this.$emit('input', this.values)
		}    
	},
	created() {
		var v = Array.from(this.value)

		if (!v.length) {
			this.values.push({})
		}else{
			this.values = v
		}
	}
}
</script>