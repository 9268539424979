<template>
<b-tab lazy :title-link-class="{ 'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> General
    </template>
    <validation-observer ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('create_patient')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
        <h5>Personal Information</h5>
        <b-form-group>
            <b-row>
                <b-col cols="6">
                    <validation-provider vid="title_id" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-select id="title_id" autofocus name="title_id" plain v-model="form.title_id">
                            <b-form-select-option :value="null">Select Title</b-form-select-option>
                            <b-form-select-option v-for="title in titles" :key="title.id" :value="title.id">{{ title.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    </validation-provider>
                    <validation-provider vid="first_name" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-input id="first_name" autocomplete="off" v-capitalize placeholder="* First name" v-model="form.first_name"/>
                    </b-form-group>
                    </validation-provider>
                    <validation-provider vid="surname" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-input id="surname" autocomplete="off" v-capitalize name="surname" placeholder="* Surname" v-model="form.surname"/>
                    </b-form-group>
                    </validation-provider>

                    <b-form-group>
                        <b-form-input id="profession" autocomplete="off" v-capitalize name="profession" placeholder="Profession" v-model="form.profession"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-select id="marital_status_id" name="marital_status_id" plain v-model="form.marital_status_id">
                            <b-form-select-option :value="null">Select Marital Status</b-form-select-option>
                            <b-form-select-option v-for="marital_status in marital_statuses" :key="marital_status.id" :value="marital_status.id">{{ marital_status.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    
                    <hr />

                    <b-form-group :disabled="hasAlternateAddress">
                        <b-form-row>
                            <b-col>
                                <vue-bootstrap-autocomplete v-model="query" :data="addresses" placeholder="Search Address..." :minMatchingChars="1" :serializer="addressSerializer" @hit="onHitaddress">
                                    <template slot="suggestion" slot-scope="{ data, htmlText }">
                                        <div class="d-flex align-items-center">
                                            <!-- Note: the v-html binding is used, as htmlText contains
                                            the suggestion text highlighted with <strong> tags -->
                                            <span class="ml-1" v-html="htmlText"></span>
                                            <!-- <span class="ml-auto">{{ data.parent }}</span> -->
                                        </div>
                                    </template>
                                </vue-bootstrap-autocomplete>
                            </b-col>
                            <b-col cols="auto">
                                <b-form-input id="address_number" v-capitalize name="address_number" placeholder="No." v-model="form.address_number"/>
                            </b-col>
                        </b-form-row>
                    </b-form-group>
                    <validation-provider vid="district_id" v-slot="{ errors }">
                        <b-form-group
                            :disabled="!hasAlternateAddress"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="district_id" name="district_id" plain v-model="form.district_id">
                                <b-form-select-option :value="null">Select District</b-form-select-option>
                                <b-form-select-option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider vid="resort_id" v-slot="{ errors }">
                        <b-form-group
                            :disabled="!hasAlternateAddress"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="resort_id" name="resort_id" plain v-model="form.resort_id">
                                <b-form-select-option :value="null">Select Resort</b-form-select-option>
                                <b-form-select-option v-for="resort in resorts" :key="resort.id" :value="resort.id">{{ resort.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider vid="address_alternate" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-form-checkbox v-b-tooltip.hover title="Use alternate address" id="has_address_alternate" v-model="hasAlternateAddress" @change="onAlternateAddressChange">
                            </b-form-checkbox>
                            </b-input-group-prepend>
                            <b-form-input :disabled="!hasAlternateAddress" id="address_alternate" v-capitalize name="address_alternate" placeholder="Alternate Address" v-model="form.address_alternate"/>
                            </b-input-group>
                    </b-form-group>
                    </validation-provider>
                    
                    <hr />
                    
                    <multiple-input-field v-model="form.phone_mobile" prepend="+597" rules="min:6|max:8|numeric" placeholder="Phone Mobile"></multiple-input-field>
                    
                    <validation-provider name="Number" vid="phone_landline" rules="min:6|max:8|numeric" v-slot="{ errors }">
                        <b-form-group
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-input-group prepend="+597">
                                <b-form-input type="tel" id="phone_landline" name="phone_landline" placeholder="Phone Landline" v-model="form.phone_landline"/>
                            </b-input-group>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="Email" vid="email" :rules="{ email: true }" v-slot="{ errors }">
                        <b-form-group
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-input type="email" id="email" autocomplete="off" name="email" placeholder="Email" v-model="form.email"/>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="6">
                    <validation-provider vid="biological_gender_id" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-select id="biological_gender_id" name="biological_gender_id" plain v-model="form.biological_gender_id">
                            <b-form-select-option disabled :value="null">* Select Gender</b-form-select-option>
                            <b-form-select-option v-for="biological_gender in biological_genders" :key="biological_gender.id" :value="biological_gender.id">{{ biological_gender.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    </validation-provider>

                    <b-form-group>
                        <b-form-select id="identified_gender_id" name="identified_gender_id" plain v-model="form.identified_gender_id">
                            <b-form-select-option :value="null">Select Identified Gender</b-form-select-option>
                            <b-form-select-option v-for="identified_gender in identified_genders" :key="identified_gender.id" :value="identified_gender.id">{{ identified_gender.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <validation-provider vid="date_of_birth" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-input id="date_of_birth" placeholder="* Date of birth (DD-MM-YYYY)" v-model="form.date_of_birth" v-dateformat autocomplete="off"></b-form-input>
                    </b-form-group>
                    </validation-provider>

                    <b-form-group>
                        <b-form-input id="birth_place" v-capitalize  placeholder="Birth place" v-model="form.birth_place"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-select id="ethnicity_id" name="ethnicity_id" plain v-model="form.ethnicity_id">
                            <b-form-select-option :value="null">Select Ethnicity</b-form-select-option>
                            <b-form-select-option v-for="ethnicity in ethnicities" :key="ethnicity.id" :value="ethnicity.id">{{ ethnicity.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group>
                        <b-form-select id="proclaimed_ethnicity_id" name="proclaimed_ethnicity_id" plain v-model="form.proclaimed_ethnicity_id">
                            <b-form-select-option :value="null">Select Proclaimed Ethnicity</b-form-select-option>
                            <b-form-select-option v-for="proclaimed_ethnicity in ethnicities" :key="proclaimed_ethnicity.id" :value="proclaimed_ethnicity.id">{{ proclaimed_ethnicity.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                   <b-form-group>
                        <b-form-checkbox id="twin" name="twin" v-model="form.twin">
                            Is Twin
                        </b-form-checkbox>
                    </b-form-group>

                    <hr />

                    <b-form-group>
                        <b-form-select id="nationality_id" name="nationality_id" plain v-model="form.nationality_id">
                            <b-form-select-option :value="null">Select Nationality</b-form-select-option>
                            <b-form-select-option v-for="nationality in nationalities" :key="nationality.id" :value="nationality.id">{{ nationality.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <validation-provider vid="id_number" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-form-input id="id_number" name="id_number" placeholder="Identification Number" v-model="form.id_number"/>
                    </b-form-group>
                    </validation-provider>

                    <validation-provider vid="id_type_id" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                            <b-form-checkbox v-b-tooltip.hover title="Is Foreigner" id="local_foreigner" name="local_foreigner" v-model="form.local_foreigner" @change="onLocalForeignerChange">
                            </b-form-checkbox>
                            </b-input-group-prepend>
                            <b-form-select :disabled="disableIDTypeField" id="id_type_id" name="id_type_id" plain v-model="form.id_type_id">
                                <b-form-select-option :value="null">Select Identification Type</b-form-select-option>
                                <b-form-select-option v-for="id_type in identification_types" :key="id_type.id" :value="id_type.id">{{ id_type.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                    </validation-provider>

                    <hr />

                    <b-form-group>
                        <b-form-input id="contact_first_name" v-capitalize name="contact_first_name" placeholder="Contact First name" v-model="form.contact_first_name"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-input id="contact_surname" v-capitalize name="contact_surname" placeholder="Contact Surname" v-model="form.contact_surname"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-input type="tel" id="contact_phone_mobile" name="contact_phone_mobile" placeholder="Contact Phone Mobile" v-model="form.contact_phone_mobile"/>
                    </b-form-group>

                    <b-form-group>
                        <b-form-select id="contact_relation_id" name="contact_relation_id" plain v-model="form.contact_relation_id">
                            <b-form-select-option :value="null">Select Contact Relation</b-form-select-option>
                            <b-form-select-option v-for="contact_relation in contact_relations" :key="contact_relation.id" :value="contact_relation.id">{{ contact_relation.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form-group>

        </b-overlay>
        <template>
            <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
        </template>
    </b-form>
    </validation-observer>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BaseExtension from "@/views/BaseExtension"
import _ from 'lodash'

export default BaseExtension.extend({
    name: 'general-patient-new',
    data() {
        return {
            form: new Form({

                title_id: null,

                first_name: "",
                surname: "",
                date_of_birth: null,
                birth_place: "",
                profession: "",

                marital_status_id: null,

                address_alternate: "",
                address_number: "",
                address_id: null,
                district_id: null,
                resort_id: null,
                biological_gender_id: null,
                identified_gender_id: null,
                
                ethnicity_id: null,
                proclaimed_ethnicity_id: null,
                nationality_id: null,
                twin: false,
                
                id_number: "",
                id_type_id: null,
                local_foreigner: false,
                
                phone_landline: "",
                phone_mobile: [],
                email: "",
                
                deceased_date: null,
                deceased_indicator: false,
                contact_surname: "",
                contact_first_name: "",
                contact_relation_id: null,
                contact_phone_mobile: "",
                consistency_token: null
            }),

            isDirty: false,
            hasErrors: false,

            query: "",
            hasAlternateAddress: false
        }
    },
    computed: {
        ...mapState('catalogue', {
            // Patient Registration
            titles: state => state.titles,
            marital_statuses: state => state.marital_statuses,
            addresses: state => state.addresses,
            districts: state => state.districts,
            resorts: state => state.resorts,
            biological_genders: state => state.biological_genders,
            identified_genders: state => state.identified_genders,
            ethnicities: state => state.ethnicities,
            nationalities: state => state.nationalities,
            twins: state => state.twins,
            // Other
            identification_types: state => state.identification_types,
            contact_relations: state => state.contact_relations
        }),
        formValid () {
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        disableIDTypeField() {
            return this.form.local_foreigner == false
        },
        enabledDeceasedIndicator() {
            return this.form.deceased_indicator
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        },
        query(val) {
            if (val === "") {
                this.form.populate({district_id: null, resort_id: null})
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('patients', ['createPatient']),
        onFormSubmit() {
            this.createPatient(this.form.data()).then(patient => {
                this.reset()
                this.notify('Patient created', "Patient information was created successfully")
                this.$router.push({ path: `${patient.id}/edit` }).catch(() => {})
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        addressSerializer(item) {
            return item.name
        },
        onHitaddress(address) {
            const resort = this.resorts.find(resort => resort.code == address.parent)
            const district = this.districts.find(district => district.code == resort.parent)

            this.form.populate({district_id: district.id, resort_id: resort.id, address_id: address.id})

        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        },
        onLocalForeignerChange(value) {
            if (value) this.form.populate({id_type_id: null, id_number: ""})
        },
        onDeatchIndicatorChange(value) {
            if (!value) this.form.populate({deceased_date: null})
        },
        onAlternateAddressChange(value) {
            // clear address form data
            this.form.populate({district_id: null, resort_id: null, address_id: null, address_number: "", address_alternate: ""})
            this.query = ""
        }
    },
    async mounted() {
        // Patient Registration
        await this.getCatalogueItem('titles')
        await this.getCatalogueItem('marital_statuses')
        await this.getCatalogueItem('addresses')
        await this.getCatalogueItem('districts')
        await this.getCatalogueItem('resorts')
        await this.getCatalogueItem('biological_genders')
        await this.getCatalogueItem('identified_genders')
        await this.getCatalogueItem('ethnicities')
        await this.getCatalogueItem('nationalities')
        await this.getCatalogueItem('twins')
        await this.getCatalogueItem('disabilities')

        // Other
        await this.getCatalogueItem('identification_types')
        await this.getCatalogueItem('contact_relations')
    }
})
</script>