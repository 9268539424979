import session from '@/shared/session'

const state = {
	patient: {
		prescriptions: []
	}
}

// getters
const getters = {
	getPatientPrescriptions: (state) => () => {
		return state.patient.prescriptions.filter(item => !item.is_chronic)
	},

	getPatientChronicPrescriptions: (state) => () => {
		return state.patient.prescriptions.filter(item => item.is_chronic)
	}
}

// actions
const actions = {
	async getPrescriptionsByPatientId({ commit, rootState }) {
		const prescriptions  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/prescriptions/`)

		commit("set_prescriptions", prescriptions.data)

		return prescriptions.data
	},

	createPrescriptionForCurrentPatient({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.post(`/api/prescriptions/`, {...data,  decursus: rootState.patients.current?.current_consult?.decursus_id})
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "prescriptions", { root: true })
	      })
	},

	createPatientPrescription({ commit, rootState, dispatch }, data) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.post(`/api/prescriptions/`, {...data,  decursus: rootState.decursus.current?.id})
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "prescriptions", { root: true })
	      })
	},
	updatePatientPrescription({ commit, rootState, dispatch }, { id, data }) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.patch(`/api/prescriptions/${id}/`, data)
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
	      .finally(() => {
	        dispatch("loading/end", "prescriptions", { root: true })
	      })
	},

	deletePatientPrescription({ commit, dispatch }, id) {
		dispatch("loading/load", "prescriptions", { root: true })
		return session
				.delete(`/api/prescriptions/${id}/`)
				.then(() => {
					return dispatch("getPrescriptionsByPatientId")
				})
      .finally(() => {
        dispatch("loading/end", "prescriptions", { root: true })
      })
	},
}

// mutations
const mutations = {
	set_prescriptions(state, prescriptions) {
		state.patient.prescriptions = prescriptions
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}