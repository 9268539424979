<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
	<template slot="title">
		<i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Primary
	</template>
	<validation-observer ref="observer" v-slot="{ passes }">
	<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
        <b-overlay 
        :show="$store.getters['loading/isLoading']('insurance')"
        rounded="sm"
        variant="light"
        :opacity="0.80"
        blur="2px">
		<b-form-group :disabled="isLocked">
			<b-row>
				<b-col cols="12" md="6">
                    <validation-provider vid="number" v-slot="{ errors }">
                    <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
	                    <b-form-input id="number" name="number" placeholder="* Number" v-model="form.number"/>
	                </b-form-group>
	                </validation-provider>
	                <validation-provider vid="effective_date" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                		<b-form-input id="effective_date" placeholder="Effective date (DD-MM-YYYY)" v-model="form.effective_date" v-dateformat autocomplete="off"></b-form-input>
	                </b-form-group>
	                </validation-provider>
	                <validation-provider vid="expiry_date" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                		<b-form-input id="expiry_date" placeholder="* Expiry date (DD-MM-YYYY)" v-model="form.expiry_date" v-dateformat autocomplete="off"></b-form-input>
	                </b-form-group>
	                </validation-provider>
				</b-col>
				<b-col cols="12" md="6">
					<validation-provider vid="company_id" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
	                    <b-form-select id="company_id" name="company_id" plain v-model="form.company_id">
	                        <b-form-select-option :value="null">* Select insurance company</b-form-select-option>
	                        <b-form-select-option v-for="company in insurance_companies" :key="company.id" :value="company.id">{{ company.name }}
	                        </b-form-select-option>
	                    </b-form-select>
	                </b-form-group>
	                </validation-provider>
	                <validation-provider vid="type_id" v-slot="{ errors }">
	                <b-form-group
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
	                    <b-form-select id="type_id" name="type_id" plain v-model="form.type_id">
	                        <b-form-select-option :value="null">* Select insurance type</b-form-select-option>
	                        <b-form-select-option v-for="type in insurance_types" :key="type.id" :value="type.id">{{ type.name }}
	                        </b-form-select-option>
	                    </b-form-select>
	                </b-form-group>
	                </validation-provider>
				</b-col>
			</b-row>
		</b-form-group>
	</b-overlay>
    <template v-if="checkUserPermission('insurance', 'can_change')">
        <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
        <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
        <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
    </template>
	</b-form>
	</validation-observer>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

export default TabExtension.extend({
	name: "primary-insurance",
	data() {
		return {
			form: new Form({
                is_primary: true,
				is_active: false,
				company_id: null,
				type_id: null,
				number: "",
                effective_date: null,
				expiry_date: null,
				consistency_token: null
			}),

			isLocked: true,
			isDirty: false,
			hasErrors: false
		}
	},
	computed: {
		...mapState("catalogue", {
            insurance_companies: (state) => state.insurance_companies,
            insurance_types: (state) => state.insurance_types
		}),
        ...mapGetters('insurances', ['getPrimaryInsurance']),
        primary() {
            return this.getPrimaryInsurance()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
	},
	watch: {
		primary: {
			immediate: true,
			handler(val) {
                if (val)
                    this.form.withData(val)
			}
		},
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.$emit('dirty', this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.$emit('saved', this.$vnode.key)
                }
            }
        }
	},
	methods: {
        ...mapActions("catalogue", ["getCatalogueItem", ]),
		...mapActions("insurances", ["getInsurancesForCurrentPatient", "createPatientInsurance", "updatePatientInsurance"]),
		create() {
			this.createPatientInsurance(this.form.data()).then(() => {
                this.reset()
				this.notify("Primary insurance created", "Primary insurance was created successfully")
			})
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer.setErrors(errors.response.data)
	                this.hasErrors = true
	                this.$emit('errors', this.$vnode.key)
	            }
			})
		},
		async update() {
			this.updatePatientInsurance({id: this.primary.id, insurance: this.form.data() }).then(() => {
                this.reset()
				this.notify("Primary insurance updated", "Primary insurance was updated successfully")
			})
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer.setErrors(errors.response.data)
	                this.hasErrors = true
	                this.$emit('errors', this.$vnode.key)
	            }
			})
		},
		onFormSubmit() {
			if (this.primary) {
				this.update()
			}else{
				this.create()
			}
		},
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.$emit('saved', this.$vnode.key)

            })
        }
    },
    async mounted() {
        // Insurances
        await this.getCatalogueItem('insurance_companies')
        await this.getCatalogueItem('insurance_types')
        await this.getInsurancesForCurrentPatient()
    }
})
</script>