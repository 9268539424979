<template>
	<b-card class="card-accent-danger" header-tag="header" footer-tag="footer" no-body>
		<template v-slot:header>
			<i class="fa-regular fa-droplet"></i> <strong>Blood Type</strong>
			<div class="card-header-actions">
				<b-link class="card-header-action btn-minimize" @click="visible = !visible">
					<i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
				</b-link>
			</div>
		</template>
		<b-collapse v-model="visible">
			<b-card-body>
				<validation-observer ref="observer" v-slot="{ passes }">
				<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
			        <b-overlay 
			            :show="$store.getters['loading/isLoading']('bloodtype')"
			            rounded="sm"
			            variant="light"
			            :opacity="0.80"
			            blur="2px">
			            <b-form-group :disabled="isLocked">
			                <validation-provider vid="type_id" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="3"
                                    label="Type"
                                    label-for="type_id"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-form-select id="type_id" name="type_id" plain v-model="form.type_id">
                                        <b-form-select-option disabled :value="null">* Select Type</b-form-select-option>
                                        <b-form-select-option v-for="blood_type in blood_types" :key="blood_type.id" :value="blood_type.id">{{ blood_type.name }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
			                </validation-provider>
			            </b-form-group>
			        </b-overlay>
		            <b-button class="mr-1" :disabled="isLocked || !isDirty" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
		            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
		            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa-lock"></i> Lock</b-button>
                    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
		            <b-button :disabled="noRevisions" type="button" class="float-right" variant="primary" @click="bloodTypeRevisions" v-b-tooltip.hover title="Browse Revisions">
                        <i class="fa-solid fa-clock-rotate-left"></i>      
                    </b-button>
				</b-form>
				</validation-observer>
			</b-card-body>
		</b-collapse>
	</b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
	name: "blood-type",
	data() {
		return {
			form: new Form({
				type_id: null,
				consistency_token: null
			}),

            isLocked: true,
            isDirty: false,
            hasErrors: false
		}
	},
    computed: {
        ...mapGetters('health', ['getPatientBloodType']),
        ...mapGetters('revisions', ['getRevisionsForBloodType']),
        revisions() {
            return this.getRevisionsForBloodType()
        },
        bloodtype() {
            return this.getPatientBloodType()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        noRevisions() {
            return this.revisions.length < 2
        },
        ...mapState('catalogue', {
            blood_types: state => state.blood_types,
        }),
    },
    watch: {
        bloodtype: {
            handler(val) {
                if (val) {
                    this.form.withData(val)
                    this.getBloodTypeRevisions(val.id)
                }
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('health', ['getBloodTypeByPatientId', 'createPatientBloodType', 'updatePatientBloodType']),
        ...mapActions('revisions', ['getBloodTypeRevisions', 'revertBloodTypeRevision']),
        create() {
            this.createPatientBloodType(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient blood type created", "Patient blood type was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        update() {
            this.updatePatientBloodType({id: this.bloodtype.id, bloodtype: this.form.data() }).then(() => {
                this.reset()
                this.notify("Patient blood type updated", "Patient blood type was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                }
            })
        },
        onFormSubmit() {
            if (this.bloodtype) {
                this.update()
            }else{
                this.create()
            }
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        },
        bloodTypeRevisions() {
            this.browseRevisions('Blood Type', this.revisions, this.bloodtype.revision_fields)
        },
        async reverted(version) {
            await this.revertBloodTypeRevision({id: this.bloodtype.id, version: version})
        }
    },
    async mounted() {
        await this.getCatalogueItem('blood_types')
        await this.getBloodTypeByPatientId()
    }
})
</script>