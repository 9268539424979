<template>
    <b-list-group>
        <Columns :columns="2">
            <TestListItem v-for="referral in labReferral.referrals" :referral="referral" :key="`test-referral-${referral.id}`"  />
        </Columns>
    </b-list-group>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"
import { Columns } from 'vue-columns'
import TestListItem from './TestListItem'

export default {
    name: "lab-referral-test-list",
    components: {
        Columns,
        TestListItem
    },
    props: {
        labReferral: {
            type: Object,
            default: {}
        }
    }
}
</script>