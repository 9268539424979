<template>
	<b-modal scrollable hide-header-close size="xl" title="Select Referral Tests" v-model="showModal" @ok="onOk" :ok-disabled="!hasSelected" @hidden="onHidden" no-close-on-esc no-close-on-backdrop>
		<b-card class="border-0" header-tag="header" footer-tag="footer" no-body>
			<Columns :columns="4">
				<b-form-group v-for="group in tests" :label="group.parent.name" :key="`group-${group.parent.id}`" label-size="lg" label-class="font-weight-bold">
<!-- 					<b-row>
		                <b-col v-for="column in Math.ceil(group.children.length / 50)" :key="`referral-column-${column}`">
		                    <b-form-group v-slot="{ ariaDescribedby }">
		                        <b-form-checkbox-group
		                        v-model="selected"
		                        :options="group.children.slice((column - 1) * 50, column * 50)"
		                        :aria-describedby="ariaDescribedby"
		                        text-field="name"
		                        value-field="id"
		                        stacked
		                        switches
		                        ></b-form-checkbox-group>
		                    </b-form-group>
		                </b-col>
		            </b-row> -->

					<b-form-checkbox-group
						v-model="selected"
						:options="group.children"
						text-field="name"
						value-field="id"
						stacked
						switches
					></b-form-checkbox-group>
				</b-form-group>
			</Columns>
		</b-card>
	</b-modal>
</template>
<script>
import ModalExtension from '@/core/components/modal/ModalExtension'
import { mapGetters, mapState, mapActions } from 'vuex'
import { Columns } from 'vue-columns'
import _ from 'lodash'

export default ModalExtension.extend({
	props: ['tests', 'selectedTests'],
	components: {
		Columns
	},
    data() {
        return {
        	selected: []
        }
	},
	computed: {
		hasSelected() {
			return this.selected.length > 0
		}
	},
	methods: {
		onOk(evt) {
			evt.preventDefault()
			this.$emit('selected', this.selected)
			this.hide()
		}
	},
    async mounted() {
    	this.selected = _.cloneDeep(this.selectedTests)
        this.show()
    }
})
</script>