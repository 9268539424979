<template>
	<b-card class="card-accent-primary" no-body>
		<b-table
			class="m-0"
			borderless
			head-variant="light"
			stacked="sm"
			responsive
			:items="demographics"
			:fields="fields">

			<template v-slot:cell(name)="data">
				<b-link :to="`/patients/${patient.id}/view`">{{ data.value }}</b-link> 
			</template>

			<template v-slot:cell(gender)="data">
				<i :class="data.item.icon_gender"></i> / <i :class="data.item.icon_identified_gender"></i>
			</template>

			<template v-slot:cell(insurances)="data">
				<template v-if="insurances.length > 0">
					<template v-for="insurance in insurances">
						<span> 
							{{insurance.number}} ({{toLocaleDate(insurance.expiry_date)}})
						</span>
					</template>
				</template>
				<template v-else>
					N/A
				</template>
			</template>

			<template v-slot:cell(actions)="row">
				<slot/>
			</template>
		</b-table>
		<b-container class="my-3">
	    <validation-observer ref="observer" v-slot="{ passes }">
	        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
	        <b-overlay 
	            :show="$store.getters['loading/isLoading']('memo')"
	            rounded="sm"
	            variant="light"
	            :opacity="0.80"
	            blur="2px">
	            <b-form-group :disabled="isLocked">
	                <validation-provider vid="description" v-slot="{ errors }">
	                <b-form-group
	                    :invalid-feedback="errors[0]"
	                    :state="errors[0] ? false : null">
	                    <b-form-textarea id="description" v-capitalize v-model="form.description" placeholder="Add memo..."></b-form-textarea>
	                </b-form-group>
	                </validation-provider>
	            </b-form-group>
	        </b-overlay>
	        <template v-if="checkUserPermission('memo', 'can_change')">
	            <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
	            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
	            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa fa-lock"></i> Lock</b-button>
	            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
	        </template>
	    	</b-form>
	    </validation-observer>
		</b-container>
	</b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapActions, mapState, mapGetters } from 'vuex'

export default ComponentExtension.extend({
    name: "patient-card",
	data() {
		return {
            fields: [
                { key: 'internal_number', label: 'Internal Number', class: 'align-middle text-center', },
                { key: 'name', label: 'Name', class: 'align-middle text-center', },
                { key: 'date_of_birth', label: 'Date of birth', class: 'align-middle text-center', formatter: (value) => this.toLocaleDate(value) },
                { key: 'gender', label: 'Gender', class: 'align-middle text-center', },
                { key: 'insurances', label: 'Insurances', class: 'align-middle text-center' },
                { key: 'actions', label: '', class: 'align-middle text-center' }
            ],
            demographics: [],
            insurances: [],

            form: new Form({
            	description: "",
                consistency_token: null
            }),

            isLocked: true,
            isDirty: false,
            hasErrors: false
		}
	},
	computed: {
        ...mapGetters('insurances', ['getPatientInsurances']),
        patient_insurances() {
            return this.getPatientInsurances()
        },
		...mapState("patients", {
			patient: (state) => state.current
		}),
        ...mapGetters('patients', ['getPatientMemo']),
        memo() {
            return this.getPatientMemo()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
	},
    watch: {
        memo: {
            immediate: true,
            handler(val) {
                if (val)
                    this.form.withData(val)
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        },
		patient: {
			immediate: true,
			handler(val) {
				this.demographics = [this.patient.demographics]
			}
		},
		patient_insurances: {
			immediate: true,
			handler(val) {
				this.insurances = this.patient_insurances
			}
		}
	},
	methods: {
		...mapActions("insurances", ["getInsurancesForCurrentPatient"]),
        ...mapActions('patients', ['createPatientMemo', 'updatePatientMemo']),
        create() {
            this.createPatientMemo(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient memo created", "Patient memo was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer.setErrors(errors.response.data)
	            }
            })
        },
        update() {
            this.updatePatientMemo({id: this.patient.memo.id, data: this.form.data() }).then(() => {
                this.reset()
                this.notify("Patient memo updated", "Patient memo was updated successfully")
            })
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer.setErrors(errors.response.data)
	                this.hasErrors = true
	            }
            })
        },
        onFormSubmit() {
            if (this.patient.memo) {
                this.update()
            }else{
                this.create()
            }
        },
        getErrors() {
            const errors = Object.values(this.$refs.observer.errors).flat()
            return errors.length > 0
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        }
	},
    async mounted() {
        // Insurances
        await this.getInsurancesForCurrentPatient()
    }
})
</script>