import session from '@/shared/session'

const state = {
	current: {},
	patient: {
		episodes: [],
		active_episodes: []
	}
}

// getters
const getters = {
	getCurrentEpisode: (state) => () => {
		return state.current
	},
	getEpisodes: (state) => () => {
		return state.patient.episodes
	},
	getActiveEpisodes: (state) => () => {
		return state.patient.active_episodes
	}
}

// actions
const actions = {
	setEpisode({ commit }, episode) {
		commit("set_current", episode)
	},

	async getEpisodeById({ commit, state, rootState }, id) {
		const [current, episodes, active_episodes] = await Promise.all([
			session.get(`/api/episodes/${id}/`),
			session.get(`/api/patients/${rootState.patients.current?.id}/episodes/`),
			session.get(`/api/patients/${rootState.patients.current?.id}/active_episodes/`),
		])

		commit("set_current", current.data)
		commit("set_episodes", episodes.data)
		commit("set_active_episodes", active_episodes.data)

		return state.current
	},

	async getEpisodesByCurrentPatient({ commit, state, rootState }) {

		const [all_episodes, active_episodes] = await Promise.all([
			session.get(`/api/patients/${rootState.patients.current?.id}/episodes/`),
			session.get(`/api/patients/${rootState.patients.current?.id}/active_episodes/`),
		])

		commit("set_episodes", all_episodes.data)
		commit("set_active_episodes", active_episodes.data)

		// const episodes  = await session
		// 	.get(`/api/patients/${rootState.patients.current.id}/episodes/`)

		// commit("set_episodes", episodes.data)

		return state.patient
	},

	updateEpisode({ commit, dispatch, state }, data) {
		dispatch("loading/load", "episodes", { root: true })
		return session
				.put(`/api/episodes/${state.current?.id}/`, data)
				.then(episode => {
					commit("set_current", episode.data)
				})
				.then(() => {
					return dispatch("getEpisodesByCurrentPatient")
				})
	      .finally(() => {
	        dispatch("loading/end", "episodes", { root: true })
	      })
	},

	createNewEpisode({ commit, dispatch, rootState }, data) {
		dispatch("loading/load", "episodes", { root: true })
		return session
			.post(`/api/episodes/`, {...data,  patient: rootState.patients.current?.id})
			.then(() => {
				return dispatch("getEpisodesByCurrentPatient")
			})
      .finally(() => {
        dispatch("loading/end", "episodes", { root: true })
      })
	},

	mergeEpisodes({ commit, dispatch }, data) {
		dispatch("loading/load", "episodes", { root: true })
		return session
			.post(`/api/episodes/merge/`, data)
			.then(() => {
				return dispatch("getEpisodesByCurrentPatient")
			})
      .finally(() => {
        dispatch("loading/end", "episodes", { root: true })
      })
	},

	activateEpisode({ commit, dispatch }, id) {
		dispatch("loading/load", "episodes", { root: true })
		return session
			.patch(`/api/episodes/${id}/activate/`)
			.then(() => {
				return dispatch("getEpisodesByCurrentPatient")
			})
      .finally(() => {
        dispatch("loading/end", "episodes", { root: true })
      })
	},

	bookmarkEpisode({ commit, dispatch }, data) {
		dispatch("loading/load", "episodes", { root: true })
		return session
			.patch(`/api/episodes/${data.id}/bookmark/`, data.bookmarked)
			.then(() => {
				return dispatch("getEpisodesByCurrentPatient")
			})
	      .finally(() => {
	        dispatch("loading/end", "episodes", { root: true })
	      })
	},

	sortEpisodes({ commit, dispatch }, data) {
		return session
			.patch(`/api/episodes/sort/`, data)
			.then(() => {
				return dispatch("getEpisodesByCurrentPatient")
			})
	}
}

// mutations
const mutations = {
	set_current(state, current) {
		state.current = current
	},
	set_episodes(state, episodes) {
		state.patient.episodes = episodes
	},
	set_active_episodes(state, episodes) {
		state.patient.active_episodes = episodes
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}