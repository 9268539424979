import session from '@/shared/session'
import moment from "moment"

const state = {
	sidebar: [],
	practitioner: {},
	consult_reason_types: [],
	settings: {
		calendar: {},
		days: [],
		events: []
	},
	count: {
		waiting_patients: 0,
		treated_patients: 0,
		hourly_patients: []
	},
	practitioners: []
}

// getters
const getters = {
	getEvents: (state) => () => {
		return state.settings.events
	},
	getDays: (state) => () => {
		return state.settings.days
	},
	getSettings: (state) => () => {
		return state.settings.calendar
	},
	getWaiting: (state) => () => {
		return state.count.waiting_patients
	},
	getTreated: (state) => () => {
		return state.count.treated_patients
	},
	getHourly: (state) => () => {
		return state.count.hourly_patients
	},
	getReasonTypes: (state) => () => {
		return state.consult_reason_types
	},
}

// actions
const actions = {
	async getSideBar ({ commit, state }) {
		const sidebar = await session.get(`/api/planning/sidebar/`)
		commit('set_sidebar', sidebar.data)
		return state.sidebar
	},

	async getConfig ({ commit, state }) {
		const config = await session.get(`/api/planning/practitioners/${state.practitioner?.username}/config/`)
		return config.data
	},

	loadIntervalTime ({ commit }, { start, end, interval }) {
		return new Promise((resolve, reject) => {
			let mStart = moment(start, 'HH:mm')
			let mEnd = moment(end, 'HH:mm')
			const it = []
			
			it.push(mStart.format('HH:mm'))
			while(mStart < mEnd) {
				let minutes = mStart.add(interval, "minutes")
				it.push(moment(minutes).format('HH:mm'))
			}

			resolve(it)
		})
	},

	// loadIntervalTime ({ commit }, { start, end, interval }) {
	// 	return new Promise((resolve, reject) => {
	// 		let mStart = moment(config.business_hours.START, 'HH:mm')
	// 		let mEnd = moment(config.business_hours.END, 'HH:mm')
	// 		const it = []
			
	// 		it.push(mStart.format('HH:mm'))
	// 		while(mStart < mEnd) {
	// 			let minutes = mStart.add(config.slot.INTERVAL, "minutes")
	// 			it.push(moment(minutes).format('HH:mm'))
	// 		}

	// 		resolve(it)
	// 	})
	// },

	async getUserCalendarSettings ({ commit, state }, username) {
		const settings = await session.get(`/api/planning/practitioners/${username}/calendar_settings/`)
		commit("set_settings", {key: 'calendar', value: settings.data})
		return state.settings.calendar
	},

	async getCalendarSettings ({ commit, state }) {
		const settings = await session.get(`/api/planning/practitioners/${state.practitioner?.username}/calendar_settings/`)
		commit("set_settings", {key: 'calendar', value: settings.data})
		return state.settings.calendar
	},

	async getCalendarDays ({ commit, state }) {
		const days = await session.get(`/api/planning/settings/days/`)
		commit("set_settings", {key: 'days', value: days.data})
		return state.settings.days
	},

	async getCalendarEvents ({ commit, state }) {
		const events = await session.get(`/api/planning/settings/events/`)
		commit("set_settings", {key: 'events', value: events.data})
		return state.settings.events
	},

	updateCalendarSettings({ commit, state, dispatch }, data) {
		dispatch("loading/load", "settings", { root: true })
		return session
			.put(`/api/planning/settings/${state.settings.calendar.id}/`, data)
			.then((settings) => {
				commit("set_settings", {key: 'calendar', value: settings.data})
				return state.settings.calendar
			})
			.finally(() => {
				dispatch("loading/end", "settings", { root: true })
			})
	},

	async filterPractitionerSlots({ state, commit }, data) {
		const slots = await session
			.get("/api/planning/slots/", {
				params: {...data, ...{ practitioner: state.practitioner?.id } },
			})
		return slots.data
	},

	async getPractitioners({ commit, state }) {
		const practitioners = await session.get('/api/planning/practitioners/limited/')
		commit("set_practitioners", practitioners.data)
		return state.practitioners
	},

	async getPractitionerByUsername ({ state, dispatch, commit }, username) {
		const practitioner = await session.get(`/api/planning/practitioners/${username}/`)
		commit('set_practitioner', practitioner.data)
		return state.practitioner
	},

	async getConsultReasonTypes ({ state, commit }) {
		const consult_reason_types = await session.get(`/api/planning/consult_reason_types/`)
		commit('set_consult_reason_types', consult_reason_types.data)
		return state.consult_reason_types
	},

	createConsultReasonType({ state, dispatch, commit }, {user, data}) {
		dispatch("loading/load", "consultation", { root: true })
		return session
			.post(`/api/planning/consult_reason_types/`,  {...data, practitioner: user.id})
			.then(() => {
				return dispatch('getConsultReasonTypes')
			})
			.finally(() => {
				dispatch("loading/end", "consultation", { root: true })
			})
	},

	updateConsultReasonTypeById({ commit, dispatch }, {id , data}) {
    	dispatch("loading/load", "consultation", { root: true })
		return session
			.put(`/api/planning/consult_reason_types/${id}/`, data)
			.then(() => {
				return dispatch('getConsultReasonTypes')
			})
			.finally(() => {
				dispatch("loading/end", "consultation", { root: true })
			})
	},

	deleteConsultReasonTypeById({ commit, dispatch }, id) {
		dispatch("loading/load", "consultation", { root: true })
		return session
			.delete(`/api/planning/consult_reason_types/${id}/`)
			.then(() => {
				return dispatch('getConsultReasonTypes')
			})
			.finally(() => {
				dispatch("loading/end", "consultation", { root: true })
			})
	},

	createConsultation({ state, dispatch, commit }, data) {
		dispatch("loading/load", "consultation", { root: true })
		return session
			.post(`/api/planning/consultations/`,  {...data, practitioner: state.practitioner?.id})
			.then((consultation) => {
				return consultation.data
			})
			.finally(() => {
				dispatch("loading/end", "consultation", { root: true })
			})
	},

	updateConsultationDurationById({ commit }, {id , data}) {
		return session
			.put(`/api/planning/consultations/${id}/`, data)
			.then((consultation) => {
				return consultation.data
			})
	},

	deleteConsultationById({ commit, dispatch }, id) {
		dispatch("loading/load", "consultation", { root: true })
		return session
			.delete(`/api/planning/consultations/${id}/`)
			.then((consultation) => {
				return consultation.data
			})
			.finally(() => {
				dispatch("loading/end", "consultation", { root: true })
			})
	},

	updatePatientArrived({ commit }, {id , data}) {
		return session
			.patch(`/api/planning/consultations/${id}/arrived/`, data)
			.then((consultation) => {
				return consultation.data
			})
	},

	updateConsultation({ commit }, {id , data}) {
		return session
			.patch(`/api/planning/consultations/${id}/change/`, data)
			.then((consultation) => {
				return consultation.data
			})
	},

	uploadLetter({ commit, dispatch }, {id , data}) {
    	dispatch("loading/load", "document", { root: true })
		return session
			.post(`/api/planning/consultations/${id}/letter/`, data)
			.then((consultation) => {
				return consultation.data
			})
			.finally(() => {
				dispatch("loading/end", "document", { root: true })
			})
	},

	deleteLetter({ commit, dispatch }, id) {
    	dispatch("loading/load", "document", { root: true })
		return session
			.patch(`/api/planning/consultations/${id}/delete_letter/`)
			.then((consultation) => {
				return consultation.data
			})
			.finally(() => {
				dispatch("loading/end", "document", { root: true })
			})
	},

	createBlocked({ state, dispatch, commit }, data) {
		dispatch("loading/load", "blocked", { root: true })
		return session
			.post(`/api/planning/blocked/`, {...data, practitioner: state.practitioner?.id})
			.then((blocked) => {
				return blocked.data
			})
			.finally(() => {
				dispatch("loading/end", "blocked", { root: true })
			})
	},

	updateBlockedDurationById({ commit }, {id , data}) {
		return session
			.put(`/api/planning/blocked/${id}/`, data)
			.then((blocked) => {
				return blocked.data
			})
	},

	deleteBlockedById ({ commit, dispatch }, id) {
		dispatch("loading/load", "blocked", { root: true })
		return session
		.delete(`/api/planning/blocked/${id}/`)
			.then((blocked) => {
				return blocked.data
		})
		.finally(() => {
			dispatch("loading/end", "blocked", { root: true })
		})
	},


	// Starting Page 

	async getWaitingPatients ({ commit }, username) {
		const waiting_patients = await session.get(`api/planning/practitioners/${username}/waiting_patients/`)
		commit("set_count", {key: 'waiting_patients', value: waiting_patients.data})

		return state.count.waiting_patients
	},

	async getTreatedPatients ({ commit }, username) {
		const treated_patients = await session.get(`api/planning/practitioners/${username}/treated_patients/`)
		commit("set_count", {key: 'treated_patients', value: treated_patients.data})

		return state.count.treated_patients
	},

	async getHourlyPatients ({ commit }, username) {
		const hourly_patients = await session.get(`api/planning/practitioners/${username}/hourly_patients/`)
		commit("set_count", {key: 'hourly_patients', value: hourly_patients.data})

		return state.count.hourly_patients
	},

	async getAllWaitingPatients ({ commit }, username) {
		const waiting_patients = await session.get(`api/planning/administration/${username}/waiting_patients/`)
		commit("set_count", {key: 'waiting_patients', value: waiting_patients.data})

		return state.count.waiting_patients
	},

	async getAllTreatedPatients ({ commit }, username) {
		const treated_patients = await session.get(`api/planning/administration/${username}/treated_patients/`)
		commit("set_count", {key: 'treated_patients', value: treated_patients.data})

		return state.count.treated_patients
	},

	resetState({ commit }) {
		commit("set_practitioner", {})
	},

}

// mutations
const mutations = {
	set_sidebar (state, sidebar) {
		state.sidebar = sidebar
	},
	set_uploading(state, uploading) {
		state.status.uploading = uploading
	},
	set_practitioners(state, practitioners) {
		state.practitioners = practitioners
	},
	set_practitioner(state, practitioner) {
		state.practitioner = practitioner
	},
	set_settings(state, obj) {
		state.settings[obj.key] = obj.value
	},
	set_count(state, obj) {
		state.count[obj.key] = obj.value
	},
	set_consult_reason_types(state, consult_reason_types) {
		state.consult_reason_types = consult_reason_types
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}